export function log_google_analytics_impression (params) {
    if (typeof ga === "undefined") {
        return;
    }
    ga("send", "pageview", params);
}

export function logGoogleAnalyticsEvent (category, action, label) {
    if (typeof ga === "undefined") {
        return;
    }
    ga('send', {
        hitType: 'event',
        eventCategory: category, // 'bikemarkt',
        eventAction: action, // 'gallery',
        eventLabel: label // 'image load'
    });
}
