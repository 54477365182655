let interval_timer_id
let dac7_status_response = ''

export default function() {
    // click event handler for "Steuerdaten eingeben": starts interval timer which checks if tax data was entered (`dac7_state = complete`), closes dialog in this case
    // click event handler for "Weiter ohne Steuerdaten": sends request to decline endpoint and closes dialog when status update is confirmed by response

    console.debug('§ init dac7 dialog.js');

    init_handler_enter_data()
    init_handler_decline()

    window.addEventListener('dac7-close-waiting-message', function(event) {
        if (interval_timer_id > 0) {
            clearInterval(interval_timer_id)
            interval_timer_id = 0
        }
    })
}

function init_handler_enter_data() {
    const dac7_enter_tax_data_button = document.querySelector('.js__dac7-enter-tax-data')

    if (dac7_enter_tax_data_button === null) {
        return
    }

    dac7_enter_tax_data_button.addEventListener('click', () => {
        if (interval_timer_id > 0) {
            // don't start multiple interval timers
            return
        }

        interval_timer_id = window.setInterval(() => {
            fetchStatus().then(function(response) {
                dac7_status_response = response
            })

            if (dac7_status_response === 'complete' || dac7_status_response === 'declined') {
                hideDialog()
                enableButtons()
            }
        }, 4000)
    })
}

function init_handler_decline() {
    const dac7_decline_tax_data_button = document.querySelector('.js__dac7-skip-enter-tax-data')

    if (dac7_decline_tax_data_button === null) {
        return
    }

    console.debug('§ DAC7 parent form:', document.querySelector('.js__form_dac7_submit_on_success'));

    window.addEventListener('dec7-decline', (event) => {
        event.stopPropagation()
        event.preventDefault()

        event.target.setAttribute('disabled', true)

        storeDeclinedStatus().then(function(response) {
            console.debug('§ DAC7 got response for store declined status', response);

            if (response === 'declined') {
                console.debug('§ DAC7 declined response: hide dialog');
                hideDialog()
                console.debug('§ DAC7 declined response: enable buttons');
                enableButtons()
                console.debug('§ DAC7 declined response: submit parent form');

                if (submitParentForm()) {
                    return;
                }

                if (!('nextUrl' in event.target.dataset)) {
                    console.debug('§ DAC7 declined response: nextUrl not found in event target dataset', event.target);

                    return;
                }

                console.debug('§ DAC7 declined response: redirecting to nextUrl', event.target.dataset.nextUrl);

                document.location.href = event.target.dataset.nextUrl;
            }
        })
    });

    console.debug('§ DAC7 added event listener for decline button');
}

async function fetchStatus() {
    const response = await fetch('/settings/steuerdaten/status');
    const responseData = JSON.parse(await response.text())

    return responseData.data.status;
}

async function storeDeclinedStatus() {
    const response = await fetch('/settings/steuerdaten/store', {
        method: "POST",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        redirect: "follow",
        body: mtbn.bikemarkt.csrf.name + '=' + mtbn.bikemarkt.csrf.token,
    })

    const responseData = JSON.parse(await response.text())

    return responseData.data.status
}

function hideDialog() {
    if (interval_timer_id > 0) {
        clearInterval(interval_timer_id)
    }

    // Close the waiting message/spinner
    document.querySelector('.js__dac7-wait--spinner').classList.add('hidden');
    // Close all hints
    document.querySelector('.js__dac7-wait--hints').classList.add('hidden');
    // Show success message
    document.querySelector('.js__dac7-wait--success').classList.remove('hidden');
    // remove waiting message
    window.dispatchEvent(new Event('dac7-hide-waiting-msg'))
    window.dispatchEvent(new Event('dac7-hide-reload-info'))
}

function enableButtons() {
    // enable disabled elements in page
    const disabledElements = document.querySelectorAll('.js__dac7-element-disabled')
    for (const element of disabledElements) {
        element.removeAttribute('disabled');
    }
}

function submitParentForm() {
    const form = document.querySelector('.js__form_dac7_submit_on_success')

    if (form) {
        console.debug('§ DAC7 found parent form, submitting', form);
        form.submit();

        return true;
    }

    console.debug('§ DAC7 did not find parent form');

    return false;
}
