/*
	Search history fly out

	@TODO watch query input for arrow keys and select entries
 */

/**
 * Close the flyout
 * @return {void}
 */
function searchFlyoutClose() {
	searchFlyoutNode.classList.remove('hero-search__flyout--active'); /* deprecated / only for legacy version */
	searchFlyoutNode.classList.add('hidden');
	searchFlyoutClearSuggestions();
}


/**
 * Open the flyout by adding a class
 * @return {void}
 */
function searchFlyoutOpen() {
	searchFlyoutNode.classList.add('hero-search__flyout--active'); /* deprecated / only for legacy version */
	searchFlyoutNode.classList.remove('hidden');
}


/**
 * Check if flyout is opened and return true, otherwise false
 * @return {bool}
 */
function searchFlyoutIsOpen() {
	if (
		searchFlyoutNode.classList.contains('hero-search__flyout--active') &&  /* deprecated / only for legacy version */
		!searchFlyoutNode.classList.contains('hidden')) {
		return true;
	}

	return false;
}


/**
 * Clear the sugestion list
 * @return {void}
 */
function searchFlyoutClearSuggestions() {
	$('.js__hero-search-suggestions').empty();
}


/**
 * Show the empty results message
 * @return {void}
 */
function searchShowEmptyResultsHint() {
	const empty_results_hint = $('<div class="md:ml-3 text-sm text-mtb italic">Keine Vorschläge gefunden&hellip;</div>')

	$('.js__hero-search-suggestions')
		.append(empty_results_hint)
		.css('display', 'block') /* deprecated / only for legacy version */
		.addClass('hidden');
}


/*
	From: https://davidwalsh.name/javascript-debounce-function
 */
function searchDebounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};


/**
 * Fetch 10 search suggestions and update the container with results
 * @return {Promise}
 */
const searchFlyoutUpdateDebounced = searchDebounce(function() {
	const limit_results = 10;

	const $form = $('.js__hero-search-form');
	const formParameters = $form.serialize();

	// Empty query, exit
	//if (!$form.find('.hero-search__query__input').val()) {
	if (!$form.find('.js__hero-search-input').val()) {
		return;
	}

	$.ajax({
		dataType: 'json',
		url: `/search.json?${formParameters}&limit=${limit_results}`,
		success: (response) => {
			searchFlyoutClearSuggestions();

			if (!response || !response.data) {
				searchShowEmptyResultsHint();
				return;
			}

			const results = response.data.results;
			if (results.length <= 0) {
				searchShowEmptyResultsHint();
  			return;
  		}

			let suggestions = $('<div/>');
  		const suggestionContainer = document.querySelector('.js__hero-search-suggestions');

  		results.forEach((res) => {
  			let suggestion = document.createElement('a');
  			suggestion.classList.add('hero-search__suggestions__entry');
  			suggestion.setAttribute('href', res.url);
  			suggestion.innerHTML = `
  				<span class="hero-search__suggestions__entry__title">${res.title}</span>
  				<span class="hero-search__suggestions__entry__price">${res.price}</span>
  			`;

  			suggestions.append(suggestion);
  		});

  		// Add all suggestions once and unhide wrapper
			$('.js__hero-search-suggestions')
				.append(suggestions)
				.css('display', 'block') /* deprecated / only for legacy version */
				.removeClass('hidden');

  		if (!searchFlyoutIsOpen()) {
				// Open flyout if not open already
				searchFlyoutOpen();
		  }
		}
	});
}, 500);


/**
 * Watch search history entries for clicks
 * @return {redirect}
 */
// function searchFlyoutWatchHistoryEntries() {
// 	const entries = searchFlyoutNode.querySelectorAll('.js__hero-search-flyout-entry');

// 	if (!entries) {
// 		return;
// 	}

// 	entries.forEach((entry) => {
// 		entry.addEventListener('click', () => {
// 			console.info(entry.dataset.url);
// 			const url = entry.dataset.url;
// 			const id = (entry.dataset.id) ? entry.dataset.id : false;
			
// 			if (id && url) {
// 				let origin = new URL(window.location.origin);
// 				//return window.location.href = `${origin}${url}`;
// 			}
// 		});
// 	});
// }


/**
 * Watch clicks on document and if the flyout is open, close it if not 
 * clicked inside the query-wrap container (input & flyout)
 * @return {void}
 */
function searchFlyoutCloseOnOutsideClick() {
	document.addEventListener('click', (event) => {
		if (!searchFlyoutIsOpen) {
			return;
		}

		if (!event.target.closest('.js__hero-search-query-wrap')) {
			searchFlyoutClose(searchFlyoutNode);
			return;
		}
	});
}


/**
 * Remove entries from search suggestions
 * @return {void}
 */
function searchFlyoutWatchHistoryDelete() {
	const deleteButtons = document.querySelectorAll('.js__hero-search-delete-entry');
	if (!deleteButtons) {
		return;
	}

	const csrfToken = document.querySelector('#csrftoken');

	deleteButtons.forEach((btn) => {
		btn.addEventListener('click' ,() => {
			const parent = btn.closest('.js__hero-search-flyout-entry');
			
      $.post('/api/search/delete-history-item', {
          'id': btn.dataset.id,
          '__csrf_key': csrfToken.value
      }, () => {
          $(parent).fadeOut();
      });
		});
	});
}


/**
 * Init and run all the flyout eventlisteners for search suggestions 
 * and previous user searches
 * 
 * @param  {nodeElement} searchFlyoutNode
 * @return {void}
 */
function searchFlyoutInit(searchFlyoutNode) {
	const queryInput = document.querySelector('.js__hero-search-input');
	
	// Is user search history available in flyout?
	const userHistoryAvailable = (queryInput.dataset.historyAvailable == 1) ? true : false;

	if (userHistoryAvailable) {
		// watch query input for focus and open flyout directly if user has 
		// a search history which is already available inside the view
		queryInput.addEventListener('focus', () => {
			searchFlyoutOpen(searchFlyoutNode);
		});

		searchFlyoutWatchHistoryDelete();
	}

	queryInput.addEventListener('keyup', (event) => {
		// watch query input for escape key and close flyout
		if (event.key === 'Escape') {
			searchFlyoutClose(searchFlyoutNode);
			return;
		}

		// Empty suggestions on emptied query field
		if (event.target.value.length <= 1) {
			searchFlyoutClearSuggestions();
			return;
		}

		// fetch results if long enough
		if (event.target.value.length >= 2) {
			searchFlyoutUpdateDebounced();
		}
	});

	//searchFlyoutWatchHistoryEntries();
	searchFlyoutCloseOnOutsideClick();
}


const searchFlyoutNode = document.querySelector('.js__hero-search__flyout');
if (searchFlyoutNode) {
	searchFlyoutInit(searchFlyoutNode);
}

