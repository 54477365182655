import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.start()

import LazyLoad from "vanilla-lazyload";
let lazyLoadInstance = new LazyLoad({});

import $ from 'jquery'
import init_ux from './ux/ux'
import images_init from './images/images'
import init_infinite_scrolling from './ux/infinite-scrolling'
import init_favorites from './favorites/favorites'
import init_live_updates from './items/live-update'
import init_session from './session/keep-alive'
import init_takeover from './ads/takeover'

window.jQuery = $
window.$ = $

jQuery(() => {
    init_ux()
    init_session()
    images_init()
    init_infinite_scrolling()
    init_favorites()
    init_live_updates()
    init_takeover()
})
