/*
	Language switch for bikemarkt navbar
 */

/**
 * Get the href attribute from link:hreflang attribute in page header 
 * for language redirection
 * 
 * @param {string} language
 * @return {string}
 */
const getHrefUrl = function (language) {
	const href = document.querySelector(`link[hreflang=${language}]`);
	if (href) {
		return href.getAttribute('href');
	}

	return window.location.href;
};


/*
	Switch language via buttons that are watched
 */
const switchLanguageListener = (ele) => {
	ele.addEventListener('click', function () {
		// API token
		const token = document.querySelector('#mtbnews-navbar').dataset.token;
		const language = ele.dataset.language;
		const currentUrl = getHrefUrl(language);

		// Parameters for API request
		const params = `?redirect=${encodeURI(currentUrl)}&token=${token}&lang=${language}`;

		window.location.href = `https://api.mtb-news.de/language/switch${params}`;
	});
};

const langToggle = document.querySelectorAll('.js__headerLanguageToggle');
if (langToggle) {
	langToggle.forEach((ele) => {
		switchLanguageListener(ele);
	});
}