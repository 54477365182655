const EARTH_RADIUS = 6371000;

export default () => {
    const distanceThreshold = mtbn.bikemarkt.settings.show_nearby_items_distance;
    if (distanceThreshold === 0) {
        return;
    }
    if (!isValidLat(mtbn.bikemarkt.settings.lat) || !isValidLng(mtbn.bikemarkt.settings.lng)) {
        return;
    }

    const pickupOverlays = document.querySelectorAll('.js__pickup-overlay');

    if (pickupOverlays.length === 0) {
        return;
    }

    for (let i of pickupOverlays) {
        if (!isValidLat(i.dataset['lat']) || !isValidLng(i.dataset['lng'])) {
            continue;
        }

        const distance = haversine(
            {latitude: i.dataset['lat'], longitude: i.dataset['lng']},
            {latitude: mtbn.bikemarkt.settings.lat, longitude: mtbn.bikemarkt.settings.lng}
        );

        if (distance > distanceThreshold) {
            continue;
        }

        const distanceElement = i.querySelector('.js__distance');
        distanceElement.textContent = Math.round(distance / 1000).toString();
        i.classList.remove('invisible');
    }
};

const isValidLat = (lat) => {
    return isDefinedAndNumeric(lat) && lat >= -90 && lat <= 90;
}

const isValidLng = (lng) => {
    return isDefinedAndNumeric(lng) && lng >= -180 && lng <= 180;
}

const isDefinedAndNumeric = (value) => {
    return typeof value !== 'undefined' && isFinite(value);
}

const haversine = (start, end) => {
    const startLatitude = getRadians(start.latitude);
    const endLatitude = getRadians(end.latitude);
    const longitudeDelta = getRadians(end.longitude - start.longitude);

    return EARTH_RADIUS * Math.acos((Math.sin(startLatitude) * Math.sin(endLatitude)) + (Math.cos(startLatitude) * Math.cos(endLatitude) * Math.cos(longitudeDelta)));
}

function getRadians(value) {
    return value * Math.PI / 180;
}
