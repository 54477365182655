import {log_google_analytics_impression} from "./google-analytics"
import log_plausible_impression from "./plausible"

export function logPageImpression(params) {
    if (!mtbn.bikemarkt.config.ajax_track_impressions) {
        return;
    }

    params = params || {};

    log_plausible_impression();
    log_google_analytics_impression(params);
}
