let isMobile;
let takeover_click_handler_enabled = true;
const mobileViewportWidth = 980;

export default function () {
    trackImpression();
    addClickHandlers();
}

const addClickHandlers = () => {
    document.addEventListener('click', (event) => {
        if (!takeover_click_handler_enabled) {
            return;
        }

        if (event.target.tagName !== 'HTML' && event.target.tagName !== 'BODY') {
            console.debug('Takeover click not @ HTML or BODY:', event.target.tagName);
            return;
        }

        isMobile = window.innerWidth < mobileViewportWidth;
        const creativeIdMobile = document.body.dataset.tmbUuid;
        const creativeIdDesktop = document.body.dataset.tbgUuid;
        const creativeId = isMobile ? creativeIdMobile : creativeIdDesktop;

        console.debug('click', creativeId);

        if (!creativeId) {
            return;
        }

        if (!document.body.classList.contains('js__homepage')) {
            return;
        }

        redirect(creativeId);
    });

    document.addEventListener('click', (event) => {
        const creativeId = event.target.dataset.ttpUuid;

        if (!creativeId) {
            console.debug('click handler, no creative found for data=ttpUuid');
            return;
        }

        console.debug('Redirecting ... ', creativeId);

        redirect(creativeId);
    });

    document.addEventListener('click', (event) => {
        const creativeId = event.target.dataset.tmnUuid;

        if (!creativeId) {
            console.debug('click handler, no creative found for data=tmnUuid');
            return;
        }

        console.debug('Redirecting ...', creativeId);

        redirect(creativeId);
    });
};

const trackImpression = () => {
    isMobile = window.innerWidth < mobileViewportWidth;
    let uuid = null;

    const mobileMainBanner = document.querySelector('*[data-tmb-uuid]');
    if (mobileMainBanner && isMobile) {
        uuid = mobileMainBanner.dataset.tmbUuid;
    }

    const desktopTopBanner = document.querySelector('*[data-ttp-uuid]');
    if (desktopTopBanner && uuid === null) {
        uuid = desktopTopBanner.dataset.ttpUuid;
    }

    console.debug('Takeover uuid', uuid);

    // .js__nice indicates that a takeover is active
    if (!document.body.classList.contains('js__nice')) {
        return;
    }

    const trackingType = getTrackingType();

    if (isMobile && !document.body.classList.contains('js__homepage')) {
        return;
    }

    if (!uuid) {
        console.error('No uuid found');
        return;
    }

    // track impressions
    fetch(`/img/${trackingType}/${uuid}.png`)
        .then(response => {
            console.debug(response);
        })
        .catch((error) => console.error('Error:', error));
};

function redirect(uuid) {
    window.open(
        '/redirect/t/' + uuid,
        '_blank'
    );
}

const getTrackingType = () => {
    const isMobile = window.innerWidth < mobileViewportWidth;
    const body = document.querySelector('body');

    if (isMobile) {
        return 'm';
    }

    if (body.classList.contains('js__homepage')) {
        return 'd';
    }

    return 'dt';
};

export function disableTakeoverClickHandler() {
    takeover_click_handler_enabled = false;
}

export function enableTakeoverClickHandler() {
    takeover_click_handler_enabled = true;
}
