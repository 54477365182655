import keyboard_shortcuts_init from "./keyboard-shortcuts";
import init_dac7_dialog from "../dac7/dialog";
import "./navbar-language-switch";
import "./search-flyout";
import initNearbyItems from "./nearby-items";

export default function init() {
    init_confirm_form_submits();
    init_voucher_redeem_form();
    init_search_form();
    init_category_group_expand_toggle();
    init_tool_tips();
    init_popovers();
    //init_spam_message_info();
    init_sharer_modal();
    init_secured_buttons();
    init_auto_select_fields();
    init_wysiwyg_editor();
    init_toggle_trigger();
    init_dac7_dialog()
    keyboard_shortcuts_init();
    initNearbyItems();
}

// Toggle search container
$('.js__searchContainerToggle').click((e) => {
    e.preventDefault();
    $('.hero-search-container').slideToggle(230);
});

/*
    On focus of the query-string field, show hidden elements for
    mobile devices
 */
$('.js__hero-search-input').focus(() => {
    $('.hero-search--hidden-toggle').show();
});

function init_confirm_form_submits() {
    $("form.confirm-submit").on("submit", function () {
        var message = $(this).data('msg-confirm');
        if (!message) {
            message = mtbn.bikemarkt.strings.confirm_dialog_message;
        }
        return window.confirm(message);
    });
}

function init_voucher_redeem_form() {
    // only submit if an article was selected
    $("form.voucher-redeem-form").on("submit", function () {
        let valid = $("select", this).val() !== "";
        if (!valid) {
            window.alert($(this).data('msg-select-item'));
        }

        return valid;
    });

    $("form.voucher-redeem-form select").on("change", function () {
        let $submit = $(this).parents("form").find(":submit");
        if ($(this).val() !== "") {
            $submit.prop("disabled", false);

            return;
        }
        $submit.prop("disabled", true);
    });
}

/**
 * Enable the trigger which expands the hidden extended search form
 * fields and the subscribe button.
 */
function init_search_form() {
    $(".mtbnews-bikemarkt-search-extended__trigger", ".mtbnews-bikemarkt-search__form").on("click", function () {
        $(".mtbnews-bikemarkt-search-extended__container").removeClass("hidden");
        $(".mtbnews-bikemarkt-search-subscribe__container").removeClass("hidden");
        $(this).hide();
    });
}

function init_category_group_expand_toggle() {
    $(document).on("click", "#categoryGroupExpandToggle", function () {
        $(".collapsed-category-tree").toggle("fast");
        $("i", $(this)).toggleClass("fa-chevron-up").toggleClass("fa-chevron-down");
    });
}

function init_tool_tips() {
    $("body").tooltip({ // bootstrap
        selector: '[rel=tooltip]'
    });
}

function init_popovers() {
    $("[rel=popover]").popover(
        {
            trigger: "hover",
            html: true
        }
    );
}

// Deprecated / re-implementated with alpineJS
// function init_spam_message_info() {
//     $(document).on("click", "div.messageSpamInfo", function () {
//         $(this).siblings("div.messageText").fadeIn();
//     });
// }

function init_sharer_modal() {
    if (typeof mtbn.bikemarkt.article_show_sharer === "undefined") {
        return;
    }

    const windowWidth = window.innerWidth;
    if (windowWidth < 768) {
        return;
    }

    $("#sharerModal").modal();
}

function init_secured_buttons() {
    $(document).on("click", "input.secured-button", function () {
        const $target = $(this);
        if ($target.data("enabled") !== true) {
            $("input.secured-button").data("enabled", false).removeClass("btn-danger");
            $target.data("enabled", true).addClass("btn-danger");
            $("#article-disable-reason").val($target.data('reason-template'));

            return false;
        }
    });
}

function init_auto_select_fields() {
    $("input.autoselect").on("click", function () {
        $(this).select();
    });
}

function init_toggle_trigger() {
    $(".bikemarkt-toggle-trigger").on("click", function (event) {
        let $trigger = $(this);
        $($trigger.data("target")).toggle();
        $trigger.toggleClass("active");
    });
}

function init_wysiwyg_editor() {
    if (!$.sceditor) {
        return;
    }

    $("textarea.wysiwyg").sceditor(
        {
            height: 300,
            width: "100%",
            format: "bbcode",
            style: "/assets/sceditor-320/bikemarkt-sceditor.css",
            locale: "de-DE",
            toolbar: "bold,italic,underline|bulletlist,orderedlist|source,removeformat",
            emoticonsEnabled: false,
            enablePasteFiltering: true,
            autoExpand: true
        }
    );
}
