import add_csrf_token_to_request_data from '../support/csrf'

export default function init () {
    $(document).on('click', '.user-follow-toggle', toggleFollow)
}

function toggleFollow () {
    let $this = $(this)
    let userId = $this.data('user-id')
    let followStatus = $this.data('follow-status')

    let data = {
        user: userId
    }

    data = add_csrf_token_to_request_data(data)

    $.ajax({
        url: mtbn.bikemarkt.urls.user_favorite_toggle,
        type: 'POST',
        dataType: 'json',
        data: data,
        beforeSend: function () {
            $this.html($this.data('content-loading'))
        },
        success: function (response) {
            if (response.data.state === true) {
                setStatusToFollow($this)
            } else {
                setStatusToNotFollow($this)
                if ($this.data('remove-on-unfav').toString() === '1') {
                    $this.parents('div.userBox').fadeOut()
                }
            }

            $this.parents('.userBox').find('.user-follower-count').text(response.data.followers)
            $this.parents('.userBox').find('.user-follows-count').text(response.data.follows)

        }
    })
}

function setStatusToFollow ($element) {
    $element.data('follow-status', '1')
    $element.html($element.data('content-following'))
}

function setStatusToNotFollow ($element) {
    $element.data('follow-status', '0')
    $element.html($element.data('content-follow'))
}
