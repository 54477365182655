export default function () {
    if (! has_live_updateable_list()) {
        return;
    }

    init_live_updates();
}

function is_locked ($element) {
    return $element.data("lock") === "1";
}

function set_lock ($element) {
    $element.data("lock", "1");
}

function release_lock ($element) {
    $element.data("lock", "0");
}

function get_last_publish_timestamp ($element) {
    return $element.find("li.productItem").first().data("published");
}

function has_live_updateable_list () {
    return $(".productsList.live-updates").length > 0;
}

function remove_updated_classes ($element) {
    $(".updated-product", $element).removeClass("updated-product");
}

function insert_new_items ($list, html) {
    $(html).each(function(index, item) {
        let $item = $(item);
        if ($item.prop('tagName') !== "LI") {
            return;
        }
        $item.addClass("updated-product").hide();
        $item.prependTo($list).slideDown("slow");
    });
}

function load_new_items ($element) {
    let lastPublishTimestamp = get_last_publish_timestamp($element);
    let updateUrl = $element.data("updateurl");

    $.get(
        updateUrl,
        {
            timestamp: lastPublishTimestamp
        },
        function(response, textStatus) {
            if (textStatus !== "success") {
                // we don't release the lock in case of an error
                return;
            }
            remove_updated_classes($element);
            insert_new_items($element, response);
            release_lock($element);
        },
        "html"
    );
}

function live_update_check (element) {
    let $element = $(element);
    if (is_locked($element)) {
        return;
    }
    set_lock($element);
    load_new_items($element);
}

function init_live_updates () {
    $(".productsList.live-updates").each(function(index, element) {
        window.setInterval(
            function (elem) {
                live_update_check(elem);
            },
            10000,
            element
        );
    });
}
