import add_csrf_token_to_request_data from "../support/csrf";

export default function init() {
    init_event_listeners();
}

function init_event_listeners() {
    $(document).on("mouseenter", ".articleImageContainer", function () {
        $(this).closest("div.articleImageContainer").find("div.favoriteContainer").addClass('visible');
    });

    $(document).on("mouseleave", ".articleImageContainer", function () {
        let $favoriteToggle = $(this).closest("div.articleImageContainer").find("div.favoriteContainer");

        $favoriteToggle.removeClass('visible');
        $favoriteToggle.removeClass('success');
    });

    $(document).on("click", "div.favoriteContainer", toggle_favorite);
}

function toggle_favorite () {
    let $target = $(this);
    let articleId = $target.data('article-id');

    let data = {
        article_id: articleId,
        process: "PROCESS_FAVORITE_TOGGLE"
    };

    data = add_csrf_token_to_request_data(data);

    $.ajax({
        url: "/favorites/toggle.json",
        type: "POST",
        dataType: "json",
        data: data,
        beforeSend: function () {
            $target.addClass('loading');
        },
        success: function (data) {
            if (data.status !== "OK") {
                return "";
            }
            if (data.data === null) {
                return "";
            }

            $target.removeClass('loading');
            $target.toggleClass('active');
            $target.addClass('success');

            let $counter = $("#favoriteCounterNav");
            if ($counter) {
                let count = parseInt($counter.text());
                if (data.data.state === true) {
                    $counter.text(count + 1);
                } else {
                    $counter.text(Math.max(0, count - 1));
                }
            }

            if (parseInt($target.data("remove-on-unfav")) === 1) {
                $target.parents(".favorite-item-container").fadeOut();
            }
        }
    });
}
