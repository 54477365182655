import {logPageImpression} from "../analytics/analytics";
import {logGoogleAnalyticsEvent} from "../analytics/google-analytics";

import LazyLoad from "vanilla-lazyload";
let lazyLoadInstance = new LazyLoad({});

let options = {
    selector: ".mtbnews-bikemarkt-infinite-scroll",
    item_to_ad_ratio: 10
};

let observer = null;
let ad_slot_id = 0;

export default function () {
    if ($(options.selector).length === 0) {
        return;
    }

    init_observer();
    // init_update_urls_on_item_click();
}

function init_observer() {
    let teasers = get_teaser_to_observe();

    if (typeof teasers === "undefined") {
        return;
    }

    if ("IntersectionObserver" in window) {
        observer = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    let next_page_link = get_next_page_link();

                    if (null === next_page_link) {
                        return;
                    }

                    load_page(next_page_link);
                }
            });
        });

        observer.observe(teasers);
    }
}

function init_update_urls_on_item_click() {
    $(".mtbnews-bikemarkt-items-list").on("click", ".mtbnews-bikemarkt-infinite-scroll__item", function (event) {
        event.preventDefault();
        event.stopPropagation();
        update_address_bar($(this).data("url") + "#" + $(this).attr("id"));
        window.location.href = get_url_for_click_event(event);
    });
}

function get_url_for_click_event(event) {
    if ($(event.target).is("a")) {
        return $(event.target).attr("href");
    }

    // img
    return $(event.target).parent().attr("href");
}

function disable_observer() {
    if (typeof observer !== "object") {
        return;
    }

    observer.disconnect();
}


function get_next_page_link() {
    let $pagination = get_pagination();
    let next_page_link = $("a.pager-modern-item-page-link.active", $pagination)
        .parents('li')
        .next('li')
        .find('a')
        .attr('href');
    if (!next_page_link) {
        return null;
    }

    if (next_page_link === "#") {
        return null;
    }

    return next_page_link;
}


function get_pagination() {
    return $(".mtbnews-bikemarkt-infinite-scroll__pagination").last();
}


function remove_pagination() {
    let pagination = get_pagination();
    disable_observer();
    $(pagination).remove();
}


function get_teaser_to_observe() {
    let $teasers = $(".mtbnews-bikemarkt-infinite-scroll__item");

    if ($teasers.length > 3) {
        return $teasers[$teasers.length - 3];
    }

    return $teasers.last()[0];
}

function copy_pagination_to_top() {
    let $oldPaginationTop = $(".mtbnews-bikemarkt-infinite-scroll__pagination_top");
    let $newPaginationTop = get_pagination()
        .clone()
        .removeClass("mtbnews-bikemarkt-infinite-scroll__pagination")
        .addClass("mtbnews-bikemarkt-infinite-scroll__pagination_top");

    if ($oldPaginationTop.length) {
        replace_top_navigation($newPaginationTop);
        return;
    }

    $newPaginationTop.insertBefore(".mtbnews-bikemarkt-items-list--infinite-scroll").first();
}

function replace_top_navigation($new_navigation) {
    $(".mtbnews-bikemarkt-infinite-scroll__pagination_top").replaceWith($new_navigation);
}

function show_loading_indicator() {
    $(
        "<div/>",
        {
            class: "mtbnews-infinite-scroll__loading"
        }
    )
        .html('Weitere Artikel werden geladen … <i class="mtb-icon-spin animate-spin"></i>')
        .appendTo(".mtbnews-bikemarkt-items-list");
}

// @todo extract to module, used also in category filter
function update_address_bar(url) {
    if (typeof window.history.pushState === "undefined") {
        return;
    }

    window.history.pushState({}, window.document.title, url);
}


function remove_loading_indicator() {
    $(".mtbnews-infinite-scroll__loading").remove();
}


function get_new_ad_slot_id() {
    ad_slot_id += 1;
    return "adslot_" + ad_slot_id;
}


function append_ad_slot_to_element(element_selector) {
    if (typeof googletag.defineSlot === "undefined") {
        return;
    }

    let ad_slot_id = get_new_ad_slot_id();
    let $gptContainer = $("<div/>", {id: ad_slot_id});
    let $adContainer = $("<div/>", {class: "interjection__inner mtbnews-bikemarkt-infinite-scroll-item__insert"});
    let $newAdItem = $("<li/>", {class: "interjection mtbnews-bikemarkt-infinite-scroll__item--insert"});

    $gptContainer.appendTo(
        $adContainer.appendTo(
            $newAdItem.appendTo(element_selector)
        )
    );

    var slot = googletag.defineSlot(
        '/1003531/MTB-News_Bikemarkt_Responsive',
        [[970, 250], [468, 60], [970, 90], [250, 250], [336, 280], [300, 250], 'fluid', [728, 90], [970, 66], [320, 50]],
        ad_slot_id
    ).addService(googletag.pubads());

    googletag.display(ad_slot_id);
    googletag.pubads().refresh([slot]);
}

function log_analytics(url) {
    logPageImpression({location: url});
    logGoogleAnalyticsEvent("bikemarkt", "infinite_scroll", url);
}

function load_page(url) {
    copy_pagination_to_top();
    remove_pagination();
    show_loading_indicator();
    $.get(
        url,
        function (data) {
            update_address_bar(url);
            remove_loading_indicator();
            const $items = $(data).find(".mtbnews-bikemarkt-infinite-scroll__item");
            const $pagination = $(data).find(".mtbnews-bikemarkt-infinite-scroll__pagination");
            $items.each(function (index, item) {
                $(".productsList.mtbnews-bikemarkt-infinite-scroll").append(item);
                if (index % options.item_to_ad_ratio === 0) {
                    append_ad_slot_to_element(".productsList.mtbnews-bikemarkt-infinite-scroll");
                }
            });
            replace_top_navigation($pagination.addClass("mtbnews-bikemarkt-infinite-scroll__pagination_top"));
            //init_images_lazy_loading();
            lazyLoadInstance.update();
            init_observer();
            log_analytics(url);
        }
    );
}

