export default function init() {
    $(document).on("keyup", function (event) {
        const $target = $(event.target);
        if ($target.get(0).tagName.toLowerCase() !== "body") {
            return;
        }

        if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
            return;
        }

        if (event.keyCode === 37 || event.keyCode === 75) {
            // previous page: cursor left or 'k'
            var $linkPrev = $("div.pagination a.active").first().parent().parent().find("a.prev");
            if ($linkPrev.length > 0) {
                window.location.href = $linkPrev.attr('href');
            }
        } else if (event.keyCode === 39 || event.keyCode === 74) {
            // next page: cursor right or 'j'
            var $linkNext = $("div.pagination a.active").first().parent().parent().find("a.next");
            if ($linkNext.length > 0) {
                window.location.href = $linkNext.attr('href');
            }
        }
    });
}
