export default function () {
    if (null !== mtbn.bikemarkt.session.keep_alive) {
        return
    }

    mtbn.bikemarkt.session.keep_alive = window.setInterval(
        function () {
            $.get(mtbn.bikemarkt.session.url_refresh, function (response) {
                try {
                    $('input.__csrf_key').val(response.data.csrf.token)
                    mtbn.bikemarkt.csrf.token = response.data.csrf.token
                } catch (error) {
                    console.warn('No CSRF token found in response', response)
                }
            })
        },
        120000
    )
}
