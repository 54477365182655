export default function add_csrf_token_to_request_data(request_data) {
    request_data[mtbn.bikemarkt.csrf.name] = mtbn.bikemarkt.csrf.token;

    return request_data;
}

export const getCsrfName = () => {
    return mtbn.bikemarkt.csrf.name || '__csrf_key';
}

export const getCsrfToken = () => {
    return mtbn.bikemarkt.csrf.token || '';
}
